import {
    SESSION_STARTED, SESSION_UPDATED
} from "../../utils/actions-factory";

const updatePictures = (state, action) => {

    if (state === undefined) {
        return {
            guid: '',
            latestSessionId: ''
        };
    }

    switch (action.type) {

        case SESSION_STARTED: {
            if (!action.payload) {
                console.log('Error during session data')
                return state.session;
            }

            console.log(action.payload)

            return {
                ...state.session,
                guid: action.payload.guid,
                latestSessionId: action.payload._id
            }
        }

        case SESSION_UPDATED: {
            if (!action.payload) {
                console.log('Error during session data')
                return state.session;
            }

            return {
                ...state.session,
                latestSessionId: action.payload._id
            }
        }



        default:
            return state.session;
    }
};

export default updatePictures;
