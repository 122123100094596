
const SERVER_URL = process.env.REACT_APP_API_URL
const CONNECTION_ERROR_MESSAGE = 'Error during data request';

export const URL_PICTURES = '/pictures';
export const URL_CONTENT = '/content';
export const URL_INVOICES = '/invoice';
export const URL_STATISTICS = '/statistics';
export const URL_ACCOUNT = '/account';

export const fetcher = url => fetch(`${SERVER_URL}${url}`).then(r => r.json())

export const getAsyncFetch = async (urlPath, method='GET', params=null) => {
    try {
        const url = SERVER_URL + urlPath;

        const request = {
            method: method,
            headers: (params && params.noJSON) ? {} : {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            ...(params && {body: params.noJSON ? params.item : JSON.stringify(params)}),
        }

        const response = await fetch(url, request);
        if (!response.ok) {
            console.log(`An error : ${response.status}`)
            return Promise.reject(CONNECTION_ERROR_MESSAGE)
        }
        const json = await response.json();
        if (!json.result) {
            //console.log(`An error during json parse`)
            //return Promise.reject(CONNECTION_ERROR_MESSAGE)
        }
        return json.result
    } catch (err) {
        console.log(err)
        return null;
    }
}
