import APIService from "../../services/api-service/api-service";
import {
    createAction,
    CONTENT_LOADED, CONTENT_ERROR
} from "../../utils/actions-factory";
const apiService = new APIService();

//Methods

export const getContent = (dispatch) => () => {
    apiService.getContent()
        .then((data) => dispatch(createAction(CONTENT_LOADED, data)))
        .catch((err) => dispatch(createAction(CONTENT_ERROR, err)));
};
