import React, {useState} from "react";
import {normalizeNumber} from "../../utils/helpers";
//import InputLabeled from "../ui/controls/input-labeled";

const LOCKED_DAYS = 1;

const isLocked = (createdDate) => {
    const safeDate = new Date().setDate(new Date().getDate() - LOCKED_DAYS)
    return safeDate <= new Date(createdDate)
}

const AccountDetails = ({ data, email, onUpdate  }) =>  {

    const totalObj = {
        amount: 0,
        fee: 0,
        tax: 0,
        locked: 0,
        paid: 0
    }

    let userName = ''
    data.forEach(picture => {
        if (picture.name) userName = picture.name;
        if (picture.payments) {
            picture.payments.forEach(payment => {
                totalObj.amount += (payment.amount || 0)
                totalObj.fee += (payment.fee || 0)
                totalObj.tax += (payment.tax || 0)
                totalObj.paid += (payment.paid || 0)
                totalObj.locked += (isLocked(payment.createdAt) ? (payment.amount - payment.fee - payment.tax) : 0 || 0)
            })
        }
    })

    //const [name, setName] = useState('')
    //const [phone, setPhone] = useState('')
    //const [card, setCard] = useState('')
    //const [address, setAddress] = useState('')

    const totalToRequest = totalObj.amount - totalObj.fee - totalObj.tax - totalObj.paid - totalObj.locked

    /*
    const updatePaymentsHandler = () => {
        const dataToUpdate = []
        data.forEach(picture => {
            if (picture.payments) {
                picture.payments.forEach(payment => {
                    if (!isLocked(payment.createdAt)){
                        if (!payment.paid) dataToUpdate.push(payment._id)
                    }
                })
            }
        })
        onUpdate && onUpdate(dataToUpdate, name, phone, card, address)
    }

     */

    return (
        <div>
            <h1>{email}</h1>
            <div className='d-flex-wrap mt-30' style={{gap: 10}}>
                <div className='content-block w-275 wMobile'>
                    <span>Сума до виводу:</span>
                    <h1>£{normalizeNumber(totalToRequest / 100)} *</h1>
                </div>
                <div className='p-20'>
                    <span>Куплено:</span>
                    <p><span>£</span>{normalizeNumber(totalObj.amount ? (totalObj.amount / 100) : 0)}</p>
                </div>
                <div className='p-20'>
                    <span>Комісія Stripe:</span>
                    <p><span>£</span>{normalizeNumber(totalObj.fee ? (totalObj.fee / 100) : 0)}</p>
                </div>
                <div className='p-20'>
                    <span>Податок з продажів (VAT):</span>
                    <p><span>£</span>{normalizeNumber(totalObj.tax ? (totalObj.tax / 100) : 0)}</p>
                </div>
                <div className='p-20'>
                    <span>На утриманні**:</span>
                    <p><span>£</span>{normalizeNumber(totalObj.locked ? (totalObj.locked / 100) : 0)}</p>
                </div>
                <div className='p-20'>
                    <span>Ви запросили вивести:</span>
                    <p><span>£</span>{normalizeNumber(totalObj.paid ? (totalObj.paid / 100) : 0)}</p>
                </div>
            </div>
            {/*<div className='mt-30'>
                <div>
                    Щоб вивести гроші вам знадобиться обліковий запис у банку Wise (<a href={'https://wise.com/'}
                                                                                       target={'_blank'}
                                                                                       rel={'noreferrer noopener'}>https://wise.com/</a>).< br/>< br/>
                    <strong>Інструкція:</strong>
                    <ol>
                        <li>Створіть EUR банківський рахунок у банку Wise (<a href={'https://wise.com/'}
                                                                              target={'_blank'}
                                                                              rel={'noreferrer noopener'}>https://wise.com/</a>)</li>
                        <li>Зайдіть в Payments (Універсальна кнопка).</li>
                        <li>В Payment tools натисніть на Your Wisetag, та створіть його.</li>
                        <li>Скопіюйте Wisetag та введіть його в поле нижче.</li>
                        <li>Натисніть на 'Вивести через Wise'.</li>
                    </ol>
                </div>
                < br/>
                <div>
                    Ми збираємо всі запити до суботи 23:59 і робимо висновки у неділю. Якщо Wisetag є вірним, гроші повинні прийти миттєво. З банку Wise ви можете вивести гроші на зручний вам банківський рахунок.
                </div>
            </div>*/}
            {/*<div className='mt-30'>
                <div className='mt-30'>
                    <label>Name</label>
                    <InputLabeled className='w-300 mt-5' value={name} onChange={setName}/>
                </div>
                <div className='mt-20'>
                    <label>Phone</label>
                    <InputLabeled className='w-300 mt-5' value={phone} onChange={setPhone}/>
                </div>
                <div className='mt-20'>
                    <label>Card</label>
                    <InputLabeled className='w-300 mt-5' value={card} onChange={setCard}/>
                </div>
                <div className='mt-20'>
                    <label>Address</label>
                    <InputLabeled className='w-300 mt-5' value={address} onChange={setAddress}/>
                </div>
            </div>*/}
            {/*<div className='mt-30'>
                <button disabled={!totalToRequest || !name} className='button-main mxw-320 wMobile pl-30 pr-30'
                        onClick={() => window.confirm(`Ви впевнені, що хочете вивести?`) && updatePaymentsHandler()}>ВИВЕСТИ
                    ЧЕРЕЗ WISE
                </button>
            </div>*/}
            {/*<div className='mt-30'>
                <p className='mt-20'>** За законом Великобританії, покупець має право оформити повернення digital-покупки протягом 14 днів. Тому гроші чекатимуть 14 днів, перш ніж їх можна буде вивести.</p>
            </div>*/}

            <div className='mt-30'>
                <p className='mt-20'>Масово переказувати гроші з Великобританії в Україну через Wise, як ми робили це раніше, стало неможливо. Ми працюємо над відновленням можливості виведення грошей. Якщо ви маєте запитання, будь ласка, напишіть на <a href={'team@leleka.me'} target={'_blank'} rel={'noreferrer noopener'}>team@leleka.me</a>. Дякуємо за ваше терпіння та розуміння.</p>
            </div>

            <div className='mt-30'>
                {data.map((item, index) => {
                    const {payments, preview, title} = item;
                    const sums = {amount: 0, fee: 0, tax: 0, paid: 0, locked: 0}
                    if (payments) {
                        payments.forEach(payment => {
                            sums.amount += (payment.amount || 0)
                            sums.fee += (payment.fee || 0)
                            sums.tax += (payment.tax || 0)
                            sums.paid += (payment.paid || 0)
                            sums.locked += (isLocked(payment.createdAt) ? (payment.amount - payment.fee - payment.tax) : 0 || 0)
                        })
                    }
                    return <div key={index} className='mt-30'>
                        <div className='separator mb-20'/>
                        <p>{title}</p>
                        <div className='d-flex-wrap mt-20' style={{gap: 10}}>
                            <img src={preview}
                                 style={{width: 100, height: 100, objectFit: 'cover', border: '5px #FFF solid'}}
                                 alt='preview'/>
                            <div className='p-20'>
                                <span>Сума до виводу:</span>
                                <p className='strong'>
                                    <span>£</span>{normalizeNumber(sums.amount ? ((sums.amount - sums.tax - sums.fee - sums.paid - sums.locked) / 100) : 0)}
                                </p>
                            </div>
                            <div className='p-20'>
                                <span>Куплено:</span>
                                <p><span>£</span>{normalizeNumber(sums.amount ? (sums.amount / 100) : 0)}</p>
                            </div>
                            <div className='p-20'>
                                <span>Комісія Stripe:</span>
                                <p><span>£</span>{normalizeNumber(sums.amount ? (sums.fee / 100) : 0)}</p>
                            </div>
                            <div className='p-20'>
                                <span>Податок з продажів (VAT):</span>
                                <p><span>£</span>{normalizeNumber(sums.tax ? (sums.tax / 100) : 0)}</p>
                            </div>
                            <div className='p-20'>
                                <span>На утриманні**:</span>
                                <p><span>£</span>{normalizeNumber(sums.locked ? (sums.locked / 100) : 0)}</p>
                            </div>
                            <div className='p-20'>
                                <span>Ви запросили вивести:</span>
                                <p><span>£</span>{normalizeNumber(sums.paid ? (sums.paid / 100) : 0)}</p>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
}


export default AccountDetails;
