import {
    PICTURES_LOADED, PICTURES_ERROR, PICTURES_FILTER
} from "../../utils/actions-factory";
import {checkAllDeSelected, searchArrays} from "../../utils/helpers";

const updatePictures = (state, action) => {

    if (state === undefined) {
        return {
            picturesOrigin: [],
            pictures: [],
            countries: [],
            themes: [],
            techniques: [],
            colors: [],
            picturesError: null
        };
    }

    switch (action.type) {

        case PICTURES_LOADED: {
            if (!action.payload) {
                console.log('Error during pictures content loading')
                return state.pictures;
            }

            const themesSet = new Set();
            const techniquesSet = new Set();
            const colorsSet = new Set();
            const countriesSet = new Set();

            action.payload.forEach(el => {
                if (el.theme) el.theme.forEach(item => themesSet.add(item))
                if (el.technique) el.technique.forEach(item => techniquesSet.add(item))
                if (el.colors) el.colors.forEach(item => colorsSet.add(item))
                if (el.country) countriesSet.add(el.country)
            })

            const themes = Array.from(themesSet).map(el => {return {selected: false, value: el}})
            const techniques = Array.from(techniquesSet).map(el => {return {selected: false, value: el}})
            const colors = Array.from(colorsSet).map(el => {return {selected: false, value: el}})
            const countries = Array.from(countriesSet).map(el => {return {selected: false, value: el}})

            return {
                ...state.pictures,
                pictures: action.payload,
                picturesOrigin: action.payload,
                themes: themes,
                techniques: techniques,
                colors: colors,
                countries: countries,
                picturesError: null
            }
        }

        case PICTURES_FILTER: {

            let themesSelectedLocal = [...state.pictures.themes]
            let techniquesSelectedLocal = [...state.pictures.techniques]
            let colorsSelectedLocal = [...state.pictures.colors]
            let countriesSelectedLocal = [...state.pictures.countries]

            const {filterName, filterValue} = action.payload;

            if (filterName === 'themes') themesSelectedLocal = filterValue;
            if (filterName === 'techniques') techniquesSelectedLocal = filterValue;
            if (filterName === 'colors') colorsSelectedLocal = filterValue;
            if (filterName === 'countries') countriesSelectedLocal = filterValue;

            const themesAll = checkAllDeSelected(themesSelectedLocal, 'selected')
            const techniquesAll = checkAllDeSelected(techniquesSelectedLocal, 'selected')
            const colorsAll = checkAllDeSelected(colorsSelectedLocal, 'selected')
            const countriesAll = checkAllDeSelected(countriesSelectedLocal, 'selected')

            const allFiltersEmpty = (themesAll && techniquesAll && colorsAll && countriesAll)

            const picturesLocal = allFiltersEmpty ? state.pictures.picturesOrigin : []
            if (!allFiltersEmpty) {
                state.pictures.picturesOrigin.forEach(el => {

                    const themesValidation = searchArrays(el.theme, themesSelectedLocal.filter(el => el.selected), 'value');
                    const techniquesValidation = searchArrays(el.technique, techniquesSelectedLocal.filter(el => el.selected), 'value');
                    const colorsValidation = searchArrays(el.colors, colorsSelectedLocal.filter(el => el.selected), 'value');
                    const countriesValidation = searchArrays([el.country], countriesSelectedLocal.filter(el => el.selected), 'value');

                    if (themesValidation || techniquesValidation || colorsValidation || countriesValidation) {
                        picturesLocal.push(el)
                    }
                })
            }


            return {
                ...state.pictures,
                pictures: picturesLocal,
                themes: themesSelectedLocal,
                techniques: techniquesSelectedLocal,
                colors: colorsSelectedLocal,
                countries: countriesSelectedLocal
            }
        }

        case PICTURES_ERROR: {
            return {
                ...state.pictures,
                picturesError: action.payload
            }
        }


        default:
            return state.pictures;
    }
};

export default updatePictures;
