import React, {useEffect, useState} from "react";
import { validate} from "../../../../utils/validators";
import {FiEye, FiEyeOff, FiX} from "react-icons/fi";

const validationErrors = {
    emailText: 'Ви ввели невірний email',
    urlText: 'Ви ввели неправильну адресу',
    emptyText: 'Поле введення не повинно бути порожнім',
    minText: 'Текст у полі введення занадто короткий',
    maxText: 'Текст у полі введення занадто довгий',
}

const InputLabeled = props =>  {

    const { submit, style, onChange, onBlur, label, value, textarea, className, containerClassName, placeholder, ico, clearable, icoSingle, minLength, maxLength, disabled } = props;

    const { type, mandatory, initValidation, onValidation} = props;


    const [validationText, setValidationText] = useState('');
    const [validationError, setValidationError] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);

    useEffect(() => {
        if (value) {
            validateContent(initValidation, value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const validateContent = (initError=true, updatedText=value, isBlur=false) => {

        const validationError = validate(updatedText, type, { mandatory: !!mandatory, min: minLength, max: maxLength })

        setValidationText (validationError ? validationErrors[validationError] : '')

        if (initError) {
            setValidationError(validationError !== '');
        }

        if (onValidation) onValidation(validationError === '')
        if (onBlur && isBlur) onBlur(updatedText)
    }

    const onChangeHandler = (text) => {
        if (onChange) onChange(text);
        validateContent(false, text)
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if (submit) submit();
        }
    }

    const typeToUse = type || 'text';

    const inputClass = (validationError ? 'validationBorder' : '') + (ico ? ' pl-45' : '') + (clearable ? ' pr-30' : '') + ` ${className || ''}`;
    const containerClass = containerClassName || '';


    return (
        <div className={ containerClass }>
            {label &&
                <p className='fs-12-10 mb-3'>{ label }
                    {mandatory && <span className='fcred fs-12'>{' *'}</span>}
                </p>
            }
            {!textarea &&
                <div className='d-flex-ac p-relative'>
                    <input type={visiblePassword ? 'text' : typeToUse}
                           placeholder={placeholder || ''}
                           disabled={disabled || false}
                           style={{opacity: disabled ? .5 : 1, ...style}}
                           className={inputClass}
                           onChange={(e) => onChangeHandler(e.target.value)}
                           onKeyDown={onKeyDown}
                           value={value}
                           onBlur={() => validateContent(true, value, true)} onFocus={() => setValidationError('')}/>
                    {ico && <div className={'input-ico-container ' + (icoSingle ? 'bg-white' : 'bg-gray')} >{ ico }</div>}
                    {(clearable && !(type === 'password') && value && value.length > 0 ) && <div className='p-absolute hp-100 d-flex-center enabledClick' onClick={() => onChangeHandler('')} style={{right: 10}}>{ <FiX size={14} /> }</div>}
                    {((type === 'password') && !visiblePassword ) && <div className='p-absolute hp-100 d-flex-center enabledClick' onClick={() => setVisiblePassword(true)} style={{right: 10}}>{ <FiEye size={14} /> }</div>}
                    {((type === 'password') && visiblePassword ) && <div className='p-absolute hp-100 d-flex-center enabledClick' onClick={() => setVisiblePassword(false)} style={{right: 10}}>{ <FiEyeOff size={14} /> }</div>}
                </div>
            }
            {textarea &&
                <textarea
                    placeholder={placeholder || ''}
                    className={inputClass}
                    onChange={(e) => onChangeHandler(e.target.value)}
                    value={value}
                    onBlur={() => validateContent(true, value, true)} onFocus={() => setValidationError('')}/>
            }
            {validationError &&
                <div className='validationError'>{validationText}</div>
            }
        </div>
    )

}

export default InputLabeled
