import {
    CONTENT_LOADED
} from "../../utils/actions-factory";

const updateContent = (state, action) => {

    if (state === undefined) {
        return {
            faq: [],
            terms: {},
            privacy: {}
        };
    }

    switch (action.type) {

        case CONTENT_LOADED: {
            if (!action.payload) {
                console.log('Error during content loading')
                return state.content;
            }

            return {
                ...state.content,
                faq: action.payload.faq,
                privacy: action.payload.privacy,
                terms: action.payload.terms,
            }
        }



        default:
            return state.content;
    }
};

export default updateContent;
