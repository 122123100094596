import React, {useEffect} from "react";
import {connect} from "react-redux";
import ReactMarkdown from 'react-markdown'
import { updateSession } from "../../redux/actions/actions-tracking";
import {ACTION_OPEN_PAGE, PAGE_FAQ, REFRESH_INTERVAL} from "../../utils/constants/tracking";

const Faq = ({ faq, updateSession }) =>  {

    useEffect(() => {
        updateSession(PAGE_FAQ, ACTION_OPEN_PAGE, {})

        let interval = null;
        interval = setInterval(() => {

            updateSession(PAGE_FAQ, ACTION_OPEN_PAGE, {})
            //getRequests();
        }, REFRESH_INTERVAL);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {/*<iframe src="https://docs.google.com/document/d/1Ia-U9bXchXp0QtH52utM6t_7Vbw4dRCZyXdO9-mXiBM/edit#heading=h.144gl4lprbza" width="100%" height="800" allow="autoplay" title='terms' />*/}
            {faq.map((item, index) => <div key={index} className='mb-50'> <ReactMarkdown className='pre-line' children={item['content-block']} /></div>)}
        </div>

    );
}

const mapStateToProps = ({ content: { faq }}) => {
    return { faq };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSession: updateSession(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
