import React, {useEffect} from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CardsList from "../cards-list";
import Filter from "../filter";
import {updateSession} from "../../redux/actions/actions-tracking";
import {connect} from "react-redux";
import {ACTION_OPEN_PAGE, PAGE_MAIN, REFRESH_INTERVAL} from "../../utils/constants/tracking";

const MainPage = ({ updateSession }) =>  {


    useEffect(() => {
        updateSession(PAGE_MAIN, ACTION_OPEN_PAGE, {})

        let interval = null;
        interval = setInterval(() => {

            updateSession(PAGE_MAIN, ACTION_OPEN_PAGE, {})
            //getRequests();
        }, REFRESH_INTERVAL);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { width } = useWindowDimensions();

    return (
        <div>
            <div className="main-page-container">
                {width > 1024 &&
                    <div className='main-page-sidebar'>
                        <Filter />
                    </div>
                }
                <div className='d-flex-1'>
                    {width <= 1024 &&
                        <div>
                            <Filter expandable />
                        </div>
                    }
                    <CardsList />
                </div>
            </div>
        </div>

    );
}


const mapStateToProps = ({ session: { guid }}) => {
    return { guid };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSession: updateSession(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
