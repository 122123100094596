import React, { useEffect, useState } from 'react';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import StripePaymentElement from "./stripe-payment-element";

import APIService from "../../services/api-service/api-service";
import {lelekaPrice} from "../../utils/constants/links";

const apiService = new APIService()

const StripeWidget = props => {

    const { email, guid, pictureId } = props

    const [invoiceId, setInvoiceId] = useState(null)

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
        betas: ['process_order_beta_1'],
        apiVersion: '2020-08-27; orders_beta=v3'
    });

    const [clientSecret, setClientSecret] = useState(null);

    const createInvoiceAndPayment = async  () => {
        const result = await apiService.createInvoice(guid, pictureId, email);
        if (result && result.paymentKey) {
            setClientSecret(result.paymentKey)
            setInvoiceId(result.invoiceId)
        }
    }

    useEffect(() => {
        createInvoiceAndPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className='mt-50'>
            <h1>Payment</h1>
            <p className='mt-10'>{email}</p>
            <p className='mt-10'>{`${lelekaPrice}`}</p>
            <div className='separator mt-20' />

            {clientSecret && invoiceId && (
                <Elements stripe={stripePromise} options={options}>
                    <StripePaymentElement invoiceId={invoiceId} pictureId={pictureId} />
                </Elements>
            )}

        </div>
    );

}



export default StripeWidget
