import React from "react";

const selectedColor = '#90DA98'

const FilterCategoryItem = props =>  {

    const { item: {selected, value}, onChange } = props;

    return (
        <div className='wp-100 containerStyle d-flex-ac pl-10 pr-10 pb-5 pt-5' style={{gap: 15}} onClick={() => onChange && onChange(!selected)}>
            <div className='boxStyle' style={{...(selected && {backgroundColor: selectedColor})}} />
            <p>{value}</p>
        </div>

    );
}

export default FilterCategoryItem
