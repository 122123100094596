import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {updateSession} from "../../redux/actions/actions-tracking";
import APIService from "../../services/api-service/api-service";
import {FiChevronLeft} from "react-icons/fi";
import {lelekaPrice} from "../../utils/constants/links";
import InputLabeled from "../ui/controls/input-labeled";
import {isEmail} from "../../utils/validators";

const apiService = new APIService()

const PicturePropose = props =>  {

    const [login, setLogin] = useState('');

    const loginHandler = async () => {

        const emailToSend = login.split(' ').join('')

        if (!isEmail(emailToSend)) {
            window.alert('Введіть свою електронну адресу у форму, щоб ми надіслали на неї анкету.');
            return;
        }

        const result = await apiService.requestUserForm(emailToSend);
        if (result) {
            window.alert('✅ Ми надіслали посилання на форму. Якщо листа немає в основній папці, будь ласка, перевірте спам.')
        }
    }

    const history = useHistory()

    return (
        <div>
            <div className='p-10 d-flex-wrap-sb-ac'>
                <div className='d-flex-ac enabledClick' style={{gap: 5}} onClick={() => history.push('/')}>
                    <FiChevronLeft size={24} />
                    <p>Home page</p>
                </div>
            </div>
            <div className='separator mt-20 mb-20' />
            <div className='mt-30'>
                <div className='content-block'>At the moment, LELEKA accepts only artworks created by kids from Ukraine</div>
                <div className='mt-30'><h2>LELEKA – сайт, де діти з України можуть продавати свої малюнки</h2></div>
                <div className='mt-30'><p>
                    Наші дизайнери перетворюють кожен малюнок на якісний digital-файл для друку, який люди можуть придбати за {`${lelekaPrice}`}.<br/><br/>
                    Ці гроші (минус ПДВ та комісія платіжних систем) ми переводимо на банківський рахунок сім'ї дитини. Це цілком волонтерський проект, і ми не беремо нічого для себе з продажу.<br/><br/>
                    <strong>Ми тимчасово не приймаємо нових малюнків</strong>, тому що ми отримали 15 000 картин. Нам потрібен час, щоб все обробити та опублікувати. Введіть свою електронну адресу, щоб ми написали вам, коли ми відкриємо прийом картин знову.

                </p></div>
                <div className='mt-30 mb-50'>
                    <span>* Ми не будемо використовувати вашу email-адресу для маркетингу та нікому її не передамо. Email потрібний нам тільки щоб надіслати вам форму, посилання на ваш аккаунт та малюнки.</span>
                    {/*<InputLabeled placeholder='введіть email' style={{border: '1px #ccc solid'}} className='mt-30 wp-30 mw-280 mb-20' initValidation type='text' value={login}
                                  onChange={(val) => setLogin(val)} mandatory submit={loginHandler}/>*/}
                    {/*<button className="button-main mt-10"
                            onClick={loginHandler}>Надіслати форму</button>*/}
                    <br/><br/>
                    <a className='mt-30' href='https://airtable.com/shr9hZmhD2kUALXbx' target='_blank' rel='noreferrer noopener'>Лист очікування</a>
                </div>

            </div>
        </div>

    );
}


const mapStateToProps = ({ pictures: { pictures }}) => {
    return { pictures };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSession: updateSession(dispatch),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PicturePropose);
