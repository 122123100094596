import APIService from "../../services/api-service/api-service";
import store from "../../store"

import {
    createAction,
    SESSION_UPDATED, SESSION_STARTED
} from "../../utils/actions-factory";
const apiService = new APIService();

//Methods

export const updateSession = (dispatch) => (page, action, meta) => {
    if (process.env.REACT_APP_COLLECT_STATS !== "on") return;

    const { guid } = store.getState().session;

    if (guid) {
        apiService.updateSession(page, action, meta)
            .then((data) => dispatch(createAction(SESSION_UPDATED, data)))
            .catch((err) => console.log(err));
    } else {
        apiService.initSession(page, action, meta)
            .then((data) => dispatch(createAction(SESSION_STARTED, data)))
            .catch((err) => console.log(err));
    }
};

