import {
    getAsyncFetch, URL_ACCOUNT, URL_CONTENT, URL_INVOICES,
    URL_PICTURES, URL_STATISTICS
} from "../../utils/fetcher";
import store from "../../store";


export default class APIService {

    getPictures = async () => {
        return getAsyncFetch(`${URL_PICTURES}`, 'GET');
    };

    getPicturesToValidate = async (token) => {
        return getAsyncFetch(`${URL_PICTURES}/validation?token=${token}`, 'GET');
    };

    publishPicture = async (obj) => {
        return getAsyncFetch(`${URL_PICTURES}/publish`, 'POST', obj);
    };

    getContent = async () => {
        return getAsyncFetch(`${URL_CONTENT}`, 'GET');
    };

    uploadPicture = async (formdata) => {
        return getAsyncFetch(`${URL_PICTURES}`, 'POST', {noJSON: true, item: formdata});
    };

    createInvoice = async (guid, pictureId, email) => {
        return getAsyncFetch(`${URL_INVOICES}`, 'POST', { guid, pictureId, email });
    };

    checkInvoice = async (invoiceId) => {
        return getAsyncFetch(`${URL_INVOICES}/payment/${invoiceId}`, 'GET');
    };

    checkPayment = async (pictureId) => {
        const { guid } = store.getState().session
        if (guid) {
            return getAsyncFetch(`${URL_INVOICES}/payment/${pictureId}/${guid}`, 'GET');
        }
    };

    getDownloadUrl = async (invoiceId) => {
        return getAsyncFetch(`${URL_INVOICES}/download/${invoiceId}`, 'GET');
    };

    createOrder = async (email, guid, pictureId) => {
        return getAsyncFetch(`${URL_INVOICES}/order`, 'POST', { email, guid, pictureId });
    };

    validateOrder = async (id) => {
        return getAsyncFetch(`${URL_INVOICES}/order/${id}`, 'POST', null);
    };

    initSession = async (page, action, meta) => {
        return getAsyncFetch(`${URL_STATISTICS}`, 'POST', { page, action, meta });
    };

    updateSession = async (page, action, meta) => {
        const { guid, latestSessionId } = store.getState().session
        return getAsyncFetch(`${URL_STATISTICS}/${latestSessionId}`, 'PUT', { guid, page, action, meta });
    };

    requestPassword = async ( email ) => {
        return getAsyncFetch(`${URL_ACCOUNT}`, 'POST', { email });
    };

    requestUserPayments = async ( email, pin ) => {
        return getAsyncFetch(`${URL_ACCOUNT}/pictures`, 'POST', { email, pin });
    };

    requestUserForm = async ( email ) => {
        return getAsyncFetch(`${URL_ACCOUNT}/join`, 'POST', { email });
    };

    updateUserPayments = async ( email, pin, paymentsToRequest, name, phone, card, address ) => {
        return getAsyncFetch(`${URL_ACCOUNT}/requested`, 'POST', { email, pin, paymentsToRequest, name, phone, card, address });
    };

}
