import React, { useState } from "react";
import InputLabeled from "../ui/controls/input-labeled";
import {isEmail} from "../../utils/validators";
import VBox from "../ui/layouts/vbox";
import APIService from "../../services/api-service/api-service";
import AccountDetails from "./account-details";
import {FiChevronLeft} from "react-icons/fi";
import {useHistory} from "react-router-dom";

const apiService = new APIService();

const AccountScreen = () =>  {

    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const [passRequested, setPassRequested] = useState(false)
    const [accountVisible, setAccountVisible] = useState(false)
    const [accountData, setAccountData] = useState([])

    const history = useHistory()

    const loginHandler = async () => {
        const result = await apiService.requestUserPayments(login, pass);
        if (result) {
            setAccountData(result)
            setPassRequested(false)
            setAccountVisible(true)
        } else {
            setPassRequested(true)
        }
    }

    const updateRequestedHandler = async (arr, name, phone, card, address) => {
        const result = await apiService.updateUserPayments(login, pass, arr, name, phone, card, address);
        if (result) {
            setAccountData(result)
            setPassRequested(false)
            setAccountVisible(true)
        } else {
            setPassRequested(true)
        }
    }

    const requestPassword = async () => {
        if (!isEmail(login)) {
            window.alert('Логін має бути електронною поштою');
            return;
        }
        const result = await apiService.requestPassword(login);
        if (result) {
            setPassRequested(true)
            window.alert('Ми надішлемо вам пароль, якщо вашу пошту зареєстровано у системі.');
        } else {
            window.alert('Помилка! Будь ласка, перевірте свою електронну пошту та переконайтеся, що ви перевірений автор на LELEKA');
            setPassRequested(false)
        }
    }

    return (
        <div className='p-20'>
            <div className='p-10 d-flex-wrap-sb-ac'>
                <div className='d-flex-ac enabledClick' style={{gap: 5}} onClick={() => history.push('/')}>
                    <FiChevronLeft size={24} />
                    <p>Home page</p>
                </div>
            </div>
            <div className='separator mt-20 mb-20' />
            <VBox full>
                {!accountVisible && <h2>Вхід до вашого особистого кабінету на LELEKA</h2>}
                {accountVisible && <h2>Ваш особистий кабінет</h2>}
                {!passRequested && !accountVisible &&
                    <div>
                        <span className='mb-20'>Введіть email-адресу, до якої прив'язані ваші малюнки. На нього прийде PIN-код для входу. </span>
                        <InputLabeled className='wp-30 mw-280 mb-20 mt-20' initValidation type='text' value={login}
                                      onChange={(val) => setLogin(val)} mandatory submit={loginHandler}/>
                        <button className="button-main mt-10"
                                onClick={requestPassword}>ОТРИМАТИ PIN</button>
                    </div>
                }

                {passRequested && !accountVisible && <div>
                    <span className='mb-10'>Введіть PIN-код, який надійшов на вашу email-адресу. Якщо PIN-кода немає, переконайтеся, що адресу введено правильно і що лист не потрапив у спам. </span>
                    <InputLabeled containerClassName='w-275 widthMobile mb-20 mt-20' initValidation type='text' value={pass} onChange={(val) => setPass(val)} mandatory submit={loginHandler} />
                    <button className="button-main mt-20" onClick={loginHandler}>УВІЙТИ ДО АКАУНТУ</button>
                    <p className='mt-50 enabledClick' onClick={() => setPassRequested(false)}><u>Назад</u></p>
                </div>}

                {accountVisible &&
                    <AccountDetails data={accountData} email={login} pass={pass} onUpdate={updateRequestedHandler} />
                }

            </VBox>
            <div className="mt-30"/>
        </div>
    );
}


export default AccountScreen;
