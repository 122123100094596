export const PAGE_MAIN = 'PAGE_MAIN';
export const PAGE_PRODUCT = 'PAGE_PRODUCT';
export const PAGE_FAQ = 'PAGE_FAQ';
export const PAGE_TERMS = 'PAGE_TERMS';
export const PAGE_PRIVACY = 'PAGE_PRIVACY';

export const ACTION_OPEN_PAGE = 'ACTION_OPEN_PAGE';
export const ACTION_OPEN_PAGE_REDIRECT = 'ACTION_OPEN_PAGE_REDIRECT';
export const ACTION_START_EMAIL = 'ACTION_START_EMAIL';
export const ACTION_START_CHECKOUT = 'ACTION_START_CHECKOUT';
export const ACTION_CHECKOUT_SUCCESS = 'ACTION_CHECKOUT_SUCCESS';
export const ACTION_CHECKOUT_ERROR = 'ACTION_CHECKOUT_ERROR';
export const ACTION_CHECKOUT_CANCEL = 'ACTION_CHECKOUT_CANCEL';
export const ACTION_DOWNLOAD_PICTURE = 'ACTION_DOWNLOAD_PICTURE';
export const ACTION_DELETE_EMAIL = 'ACTION_DELETE_EMAIL';

export const REFRESH_INTERVAL = 10 * 1000 //10s
