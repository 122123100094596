import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Route, Switch, useHistory} from "react-router-dom";
import { getPictures } from "../../redux/actions/actions-pictures";
import { getContent } from "../../redux/actions/actions-content";
import { updateSession } from "../../redux/actions/actions-tracking";

import "./main.css";
import "./controls.css";
import "./shortcuts.css";
import "./sliding-pane.css"
import Header from "../header";
import MainPage from "../main-page";
import PicturePage from "../picture-page";
import Footer from "../footer/footer";
import Terms from "../terms";
import Privacy from "../privacy";
import PictureUploadPage from "../picture-upload-page";
import Faq from "../faq";
import AccountScreen from "../account-screen";
import PicturePropose from "../picture-propose";
import {ACTION_OPEN_PAGE_REDIRECT, PAGE_MAIN} from "../../utils/constants/tracking";
import PictureValidation from "../picture-validation";


const App = props =>  {

    const { pictures, getPictures, getContent, updateSession } = props;

    const history = useHistory()

    useEffect(() => {

        getPictures();
        getContent();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const query = new URLSearchParams(window.location.search);
        const pictureId = query.get('pictureId')

        if (pictureId ) {
            updateSession(PAGE_MAIN, ACTION_OPEN_PAGE_REDIRECT, {
                pictureId: pictureId
            })
            history.push(`/${pictureId}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!pictures) return null;

    return (
        <div>
            {/*<div className='p-20 ta-center' style={{backgroundColor: '#fdff7b'}}>
                <p>Find something special at our holiday shop on Etsy. <a href='https://www.etsy.com/uk/shop/LELEKAmeShop' target='_blank' rel='noreferrer noopener' style={{color: '#2d2d2d'}}>GET ME THERE</a></p>
                <p>Our volunteers are on a break until January, 3 | Наші волонтери у відпустці до 3 січня</p>
            </div>*/}
            <div className="wrapper">
                <div className="container">
                    <Switch>
                        <Route path='/' exact>
                            <div>
                                <Header />
                                <MainPage />
                            </div>
                        </Route>
                        <Route path='/terms' exact>
                            <div>
                                <Header />
                                <Terms />
                            </div>
                        </Route>
                        <Route path='/privacy' exact>
                            <div>
                                <Header />
                                <Privacy />
                            </div>
                        </Route>
                        <Route path='/faq' exact>
                            <div>
                                <Header />
                                <Faq />
                            </div>
                        </Route>
                        {/*<Route path='/upload-zip' exact>
                            <div>
                                <Header />
                                <PictureUploadPage />
                            </div>
                        </Route>*/}
                        {/*<Route path='/validate' exact>
                            <div>
                                <PictureValidation />
                            </div>
                        </Route>*/}
                        <Route path='/account/'>
                            <div>
                                <AccountScreen />
                            </div>
                        </Route>
                        {/*<Route path='/join' exact component={PicturePropose} />*/}
                        <Route path='/:id' exact component={PicturePage} />
                    </Switch>
                    <Footer />
                </div>
            </div>
    </div>

    );
}

const mapStateToProps = ({ pictures: { pictures }}) => {
    return { pictures };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPictures: getPictures(dispatch),
        getContent: getContent(dispatch),
        updateSession: updateSession(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
