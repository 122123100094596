
import updatePictures from "./updatePictures";
import updateSession from "./updateSession";
import updateContent from "./updateContent";

const reducer = (state, action) => {
    return {
        pictures: updatePictures(state, action),
        session: updateSession(state, action),
        content: updateContent(state, action),
    };
};

export default reducer;
