import React from "react";
import logo from '../../assets/logoleleka.svg'
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {Link} from "react-router-dom";

const Header = props =>  {

    const { width } = useWindowDimensions();

    const centralBlock =  <div className='d-flex-1'>
        <p className='heading-text'>Explore artwork created by children from Ukraine. Since June 2022, through LELEKA, these children have sold artworks worth £30,769. We have now stopped sales and are focusing on transferring the funds to their families' bank accounts. </p>
    </div>

    const buttonBlockTop = null /*<div className='d-flex-1'>
        <Link to='/join'><button className='button-main wp-100'>SEND US YOUR ART</button></Link>
        <div className='ta-center mt-20'>
            <a href='https://airtable.com/shr6m9rGuyrfYNWzQ' target='_blank' rel='noreferrer noopener'><button className='button-link'>Buy many artworks at once</button></a>
        </div>
        <div className='separator mt-20 mb-20' />
    </div>*/

    return (
        <div className="header">
            {width < 600 && buttonBlockTop}
            <div className="header-top">
                <div>
                    <Link to='/'><img className='logo' src={logo} alt='logo' /></Link>
                    <p>ARTS BY KIDS FROM UKRAINE 🇺🇦</p>
                </div>
                {width >= 1280 && centralBlock}
                {width >= 600 &&
                    <div>
                        {/*<Link to='/join'>
                            <button className='button-main'>SEND US YOUR ART</button>
                        </Link>*/}
                        {/*<div className='ta-center mt-20'>
                            <a href='https://airtable.com/shr6m9rGuyrfYNWzQ' target='_blank' rel='noreferrer noopener'><button className='button-link'>Buy many artworks at once</button></a>
                        </div>*/}

                    </div>
                }

            </div>
            {width < 1280 && centralBlock}
            <div className='separator mt-20 mb-20' />
        </div>

    );
}


export default Header
