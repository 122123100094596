import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
//import {ACTION_OPEN_PAGE, PAGE_MAIN, REFRESH_INTERVAL} from "../../utils/constants/tracking";
import {lelekaPrice} from "../../utils/constants/links";

const LOADED_LIMIT = 30

const CardsList = props =>  {

    const { pictures } = props;

    const [loadedPicturesLimit, setLoadedPicturesLimit] = useState(LOADED_LIMIT)


    useEffect(() => {
        //console.log(222)
        //setLoadedPictures(pictures.slice(0, LOADED_LIMIT))
        //setHasMoreFlag(pictures.length > LOADED_LIMIT)
    }, [pictures]);

    const loadMorePictures = () => {
        const maxLength = Math.min(pictures.length, loadedPicturesLimit + LOADED_LIMIT)
        setLoadedPicturesLimit(maxLength)
    }

    const finalPictures = pictures.slice(0, loadedPicturesLimit)
    const hasMoreFlag = finalPictures.length !== pictures.length

    return (

        <div>
            <InfiniteScroll
                className='cards-container'
                next={loadMorePictures}
                hasMore={hasMoreFlag}
                loader={<p>Loading...</p>}
                dataLength={finalPictures.length}
            >
            {finalPictures.map((item, index) => {
                return item?.preview ? <div key={index} className='card-container enabledClick mb-30' >
                    <Link to={`/${item.id}`}>
                        <div className='card'>
                            <div className='card-image-holder'>
                                <img className='card-image' src={item.preview} alt='preview'/>
                            </div>
                        </div>
                    </Link>
                    {item.title && <p className='mt-5'>{item.title}</p>}
                    {<p className='mt-5'>{`${lelekaPrice}`}</p>}
                    {item.name && <div className='inactive'><span>by {item.name}</span></div>}
                </div> : null
            })}
            </InfiniteScroll>
        </div>

    );
}

const mapStateToProps = ({ pictures: { pictures }}) => {
    return { pictures };
};


export default connect(mapStateToProps)(CardsList);
