import React, {useEffect} from "react";
import {connect} from "react-redux";
import ReactMarkdown from "react-markdown";
import { updateSession } from "../../redux/actions/actions-tracking";
import {ACTION_OPEN_PAGE, PAGE_PRIVACY, REFRESH_INTERVAL} from "../../utils/constants/tracking";

const Privacy = ({privacy, updateSession}) =>  {

    useEffect(() => {
        updateSession(PAGE_PRIVACY, ACTION_OPEN_PAGE, {})

        let interval = null;
        interval = setInterval(() => {

            updateSession(PAGE_PRIVACY, ACTION_OPEN_PAGE, {})
            //getRequests();
        }, REFRESH_INTERVAL);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {/*<iframe src="https://drive.google.com/file/d/1LXvJl3d1tIzB2PTEA_zvmb75i3-xsyXX/preview" width="100%" height="800" allow="autoplay" title='terms' />*/}
            <div className='content-block'><ReactMarkdown className='pre-line' children={privacy['summary-block']} /></div>
            <div className='mt-50 mb-50'><ReactMarkdown className='pre-line' children={privacy['content-block']} /></div>
        </div>

    );
}


const mapStateToProps = ({ content: { privacy }}) => {
    return { privacy };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSession: updateSession(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
