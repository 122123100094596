import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {Link} from "react-router-dom";

const Footer = () =>  {

    const { width } = useWindowDimensions();

    return (
        <div className='p-20 p-absolute' style={{background: '#232324', left: 0, right: 0}}>
            <div className='inactive' style={{width: width < 1000 ? '100%' : '50%'}}>
                <span>LELEKA is a not-for-profit initiative created by a group of volunteers. We built LELEKA so children from Ukraine can raise money for themselves and their families and start feeling supported, useful and safe again.</span>
                <div className='mt-20 d-flex-sb'>
                    <div>
                        <Link to='/faq' className='inactive'><span>FAQ</span></Link><br />
                        <Link to='/terms' className='inactive'><span>Terms and Conditions</span></Link><br />
                        <Link to='/privacy' className='inactive'><span>Privacy policy</span></Link><br /><br />
                    </div>
                    <div>
                        {/*<Link to='/join' className='inactive'><span>Send us your art</span></Link>*/}
                    </div>
                </div>

                <span>We do not use cookies (and other similar technologies) to collect your data.</span><br />

                <span>© 2024 LELEKA ART LTD All Rights Reserved.</span>

            </div>
            {/*
            <div className='mt-30 d-flex-wrap' style={{gap: 20}}>
                <Link to='/terms'><button className='button-footer'>FAQ</button></Link>
                <Link to='/terms'><button className='button-footer'>TERMS OF SERVICE</button></Link>
                <Link to='/privacy'><button className='button-footer'>PRIVACY POLICY</button></Link>
            </div>
            */}
        </div>

    );
}


export default Footer
