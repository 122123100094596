import React, {useState} from "react";
import {connect} from "react-redux";
import VBox from "../ui/layouts/vbox";
import FilterCategory from "./filter-category";
import {FiMinus, FiPlus} from "react-icons/fi";

const Filter = props =>  {

    const { themes, techniques, colors, pictures, expandable } = props;

    const [expanded, setExpanded] = useState(false)

    return (
        <VBox full>
            <div className='d-flex-wrap-sb-ac'>
                <div>
                    <p>FILTERS</p>
                    {expandable &&<span className='mt-5 inactive'>{pictures.length} RESULTS</span>}
                </div>
                {!expandable && <p>{pictures.length} RESULTS</p>}
                {expandable && <div>
                    {expanded ? <FiMinus size={26} className='enabledClick' onClick={() => setExpanded(false)} /> : <FiPlus size={26} className='enabledClick' onClick={() => setExpanded(true)} /> }
                </div>}
            </div>
            <div className='separator'/>
            {(!expandable || (expandable && expanded)) && <div>
                {/*<FilterCategory filterName='countries' filterLabel='country' data={countries}/>*/}
                    <FilterCategory filterName='themes' filterLabel='theme' data={themes}/>
                    <FilterCategory filterName='techniques' filterLabel='technique' data={techniques}/>
                    <FilterCategory filterName='colors' filterLabel='colors' data={colors}/>
                </div>
            }
        </VBox>


    );
}


const mapStateToProps = ({ pictures: { themes, techniques, colors, countries, pictures }}) => {
    return { themes, techniques, colors, pictures, countries };
};


export default connect(mapStateToProps)(Filter);
