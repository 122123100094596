import React, {useEffect, useState} from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {Link, useHistory, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {updateSession} from "../../redux/actions/actions-tracking";
import InputLabeled from "../ui/controls/input-labeled";
import {isEmail} from "../../utils/validators";
import APIService from "../../services/api-service/api-service";
import {FiChevronLeft} from "react-icons/fi";
//import PaypalWidget from "../paypal/paypal-widget";
import {ACTION_OPEN_PAGE, PAGE_PRODUCT} from "../../utils/constants/tracking";
import StripeWidget from "../stripe/stripe-widget";
import copy from 'copy-to-clipboard';
import {lelekaPrice, lelekaURL} from "../../utils/constants/links";

import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    RedditShareButton,
    RedditIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share";

const apiService = new APIService()

const PicturePage = props =>  {

    const { pictures, guid } = props;
    const { width } = useWindowDimensions();
    const [email, setEmail] = useState('');
    const [page, setPage] = useState(0);
    const [paid, setPaid] = useState(false);
    const [links, setLinks] = useState({});

    const { updateSession } = props;

    const history = useHistory()

    const { id } = useParams();

    const checkForPayment = async () => {
        if (!paid) {
            const result = await apiService.checkPayment(id);
            if (result) {
                setPaid(true);
                setLinks(result)
            }
        }
    }

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            if (!paid) {
                checkForPayment();
            } else {
                clearInterval(interval)
            }
        }, 3000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const copyURLToClipboard = () => {
        copy(`${lelekaURL}/${id}`);
        window.alert('Link copied. Now post it on social media or send it to friends!')
    }

    useEffect(() => {
        updateSession(PAGE_PRODUCT, ACTION_OPEN_PAGE, {
            pictureId: id
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!id) return;

    const urlToShare = `${lelekaURL}?pictureId=${id}`;

    const picture = pictures.find(el => el.id === id);
    if (!picture) return null;

    const {preview, name, age, title, description, printing, format, country} = picture

    return (
        <div>
            <div className='p-10 d-flex-wrap-sb-ac'>
                <div className='d-flex-ac enabledClick' style={{gap: 5}} onClick={() => history.push('/')}>
                    <FiChevronLeft size={24} />
                    <p>Home page</p>
                </div>
                {/*<div className='d-flex' style={{gap: 10}}>
                    <FacebookShareButton url={urlToShare} >
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={urlToShare} >
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton url={urlToShare} >
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <RedditShareButton url={urlToShare} >
                        <RedditIcon size={32} round={true} />
                    </RedditShareButton>
                </div>*/}
                {/*<button className='button-clean' onClick={() => copyURLToClipboard()}>SHARE</button>*/}
            </div>
            <div className='separator mt-20 mb-20' />
            <div className='d-flex-jcc'>
                <div className='w-1400 d-flex-wrap' style={{gap: 30}}>
                    <div className='card' style={{width: width < 800 ? '100%' : '48%', height: 420}}>
                        <div className='card-image-holder' style={{maxHeight: 390}}>
                            <img className='card-image' style={{maxHeight: 350}} src={preview} alt='preview' />
                        </div>
                    </div>
                    <div style={{width: width < 800 ? '100%' : '48%'}}>
                        <h1>{title}</h1>
                        <div className='mt-10'>
                            {/*<p>{`${lelekaPrice}`} (VAT included)</p>*/}
                            <div className='inactive'><span>by {name}</span></div>
                            {age && <div className='inactive'><span>{age} y.o. | {country}</span></div>}
                        </div>
                        <div className='mt-10'>
                            <p>{description}</p>
                            <p className='fs-10' style={{color: '#aaa'}}><span>{`translated from Ukrainian with Google Translate`}</span></p>
                        </div>
                        {/*<div className='separator mt-30 mb-30' />*/}
                        {/*<div className='d-flex-sb'>
                            <p><strong>File: </strong>{format}</p>
                            <p><strong>Max print size: </strong>{printing}</p>
                        </div>*/}
                        {paid && false &&
                            <div className='bg-white p-30 mt-50' style={{borderRadius: 30}}>
                                <h2>Thank you for your purchase!</h2>
                                <p className='mt-20'>You can download your copy of the artwork here, or from the email we’ve just sent to you. *</p>
                                <p className='mt-20'>We want to help 3,000 kids make at least £100 in sales each. To help us reach this goal:</p>
                                <ul>
                                    <li><strong>Share LELEKA</strong>with at least one person</li>
                                    <li className='mt-10'>Fill out <a href='https://airtable.com/shr6m9rGuyrfYNWzQ' target='_blank' rel='noreferrer noopener'>this form</a> if you or your company want to buy <strong>100 or more previously-unsold artworks at once.</strong></li>
                                </ul>
                                <div className='d-flex-wrap-sb-ac mt-30' style={{gap: 10}}>
                                    <a className='wp-48 mw-200 wMobile' href={links.web} target='_blank' rel='noreferrer noopener'><button className='button-main wp-100 mw-200 p-20 wMobile'>DOWNLOAD IMAGE</button></a>
                                    <a className='wp-48 mw-200 wMobile' href={links.print} target='_blank' rel='noreferrer noopener'><button className='button-main wp-100 mw-200 p-20 wMobile'>DOWNLOAD PRINT FILE</button></a>
                                </div>
                                <div className='mt-20'><span>* if you cannot find an order confirmation email in your inbox, please check spam, promo and social folders.</span></div>
                            </div>
                        }
                        {page === 0 && !paid && false &&
                        <div className='mt-50'>
                            <a href={`${process.env.REACT_APP_STRIPE_LINK}${id}---${guid || 'na'}`} target='_blank' rel='noopener noreferrer'><button className='button-secondary wp-100' onClick={() => console.log(1)}>PAY & DOWNLOAD</button></a>
                            {
                                <div className='content-block mt-30'>
                                    <p>Once you make a purchase, you receive the artwork via email. If you cannot find an email in your Inbox, please check Spam, Promo and Social folders.</p>
                                </div>
                            }
                        </div>
                        }
                        {page === 1 && false &&
                            <div className='mt-50'>
                                <p>We need your email address to send you the artwork after payment. <strong>We will not use it in any other way.</strong> After receiving and downloading files you can use a special URL to delete your email address from our database.</p>
                                <p className='mt-10 mb-30'><span>By making this purchase you agree to our <Link to='/terms' className='a-black'>Terms of Service</Link> and our <Link to='/privacy' className='a-black'>Privacy Policy</Link>.</span></p>
                                <div className='mt-10 d-flex-wrap-sb-ac' style={{gap: 20}}>
                                    <InputLabeled containerClassName='d-flex-1' className='wp-100' value={email} onChange={setEmail} />
                                    <button disabled={!isEmail(email)} className='button-main' onClick={() => setPage(2)}>BUY</button>
                                </div>
                            </div>
                        }
                        {page === 2 && false &&
                            <div>
                                {<StripeWidget email={email} pictureId={id} guid={1} />}
                                {/*<PaypalWidget email={email} pictureId={id} guid={1} />*/}
                            </div>
                        }

                        {country === 'Ukraine' && false &&
                            <div className='mt-50'>
                                <h2>About arts on LELEKA</h2>
                                 <p className='mt-20'>Children from Ukraine have created this piece of art. If you purchase this artwork,
                                     <strong>{` ${lelekaPrice}`} minus the VAT, Stripe and Wise fees</strong> will be sent to the children's family bank account.<br/><br/> You’ll receive a high-quality file that you can print, frame, and hang on the wall.</p>
                                 <p className='mt-20'>LELEKA is a fully free, non-profit platform. Our team is made up of volunteers, and we don’t take commissions for transactions.</p>
                            </div>
                        }
                        <div className='separator mt-30 mb-30' />
                        {/*<div className='bg-white p-30 mb-50' style={{borderRadius: 30}}>
                            <div>{`Please share a link to this page on social media. Every download equals ${lelekaPrice} for a young artist.`}</div>
                            <div className='d-flex mt-20' style={{gap: 10}}>
                                <FacebookShareButton url={urlToShare} >
                                    <FacebookIcon size={32} round={true} />
                                </FacebookShareButton>
                                <TwitterShareButton url={urlToShare} >
                                    <TwitterIcon size={32} round={true} />
                                </TwitterShareButton>
                                <LinkedinShareButton url={urlToShare} >
                                    <LinkedinIcon size={32} round={true} />
                                </LinkedinShareButton>
                                <RedditShareButton url={urlToShare} >
                                    <RedditIcon size={32} round={true} />
                                </RedditShareButton>
                            </div>
                            <div className='mt-20'>
                                <button className='button-clean wp-100' onClick={() => copyURLToClipboard()}>COPY URL & SHARE THIS WORK</button>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>

    );
}


const mapStateToProps = ({ pictures: { pictures }, session: { guid }}) => {
    return { pictures, guid };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSession: updateSession(dispatch),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PicturePage);
