import APIService from "../../services/api-service/api-service";
import {
    createAction,
    PICTURES_LOADED, PICTURES_ERROR, PICTURES_FILTER
} from "../../utils/actions-factory";
const apiService = new APIService();

//Methods

export const getPictures = (dispatch) => () => {
    apiService.getPictures()
        .then((data) => dispatch(createAction(PICTURES_LOADED, data)))
        .catch((err) => dispatch(createAction(PICTURES_ERROR, err)));
};


export const applyFilter = (dispatch) => (filterName, filterValue) => {
    dispatch(createAction(PICTURES_FILTER, {filterName, filterValue}))
};
