import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {updateSession} from "../../redux/actions/actions-tracking";
import APIService from "../../services/api-service/api-service";

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const apiService = new APIService()

const PictureValidation = props =>  {

    const [picturesToValidate, setPicturesToValidate] = useState([])

    const [crop, setCrop] = useState()
    const [originalWidth, setOriginalWidth] = useState(0)
    const [originalHeight, setOriginalHeight] = useState(0)
    const [originalNaturalWidth, setOriginalNaturalWidth] = useState(0)
    const [originalNaturalHeight, setOriginalNaturalHeight] = useState(0)

    const [brightness, setBrightness] = useState(1)
    const [contrast, setContrast] = useState(1)
    const [saturation, setSaturation] = useState(1)

    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);

    const [index, setIndex] = useState(0)
    const [edit, setEdit] = useState(false)


    useEffect(() => {
        const loadPicturesToValidate = async (authToken) => {
            const result = await apiService.getPicturesToValidate(authToken)
            if (result) {
                setPicturesToValidate(result)
            } else {
                window.alert('error during loading images to validate list')
            }
        }

        const query = new URLSearchParams(window.location.search);
        const tokenValue = query.get('token')

        if (tokenValue) {
            setToken(tokenValue)
            loadPicturesToValidate(tokenValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (edit) {
            setCropHandler(crop || {
                x: 0,
                y: 0,
                width: originalWidth,
                height: originalHeight
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brightness, contrast, saturation]);

    const setEditHandler = (value) => {
        if (value) {

            setCropHandler({
                x: 0,
                y: 0,
                width: originalWidth,
                height: originalHeight
            })

        } else {
            setCrop(null)
        }
        setEdit(value)

    }

    const publishPicture = async () => {
        setLoading(true)
        const result = await apiService.publishPicture({
            record: picturesToValidate[index].id,
            token
        })
        if (result) {
            setLoading(false)
            console.log(result)
            setPicturesToValidate(result)
        } else {
            setLoading(false)
            window.alert('error during image publish')
        }
    }

    const uploadDocument = async () => {

        //setEdit(false)

        //if (!fileToUpload) return;

        const canvas = document.getElementById("myCanvas");

        canvas.toBlob(async (blob) => {

            const formData = new FormData();
            formData.append('file', blob);
            formData.append('record', picturesToValidate[index].id);
            formData.append('token', token);

            setLoading(true)

            const result = await apiService.uploadPicture(formData);

            if (result) {
                setLoading(false)
                setEditHandler(false)
                console.log('Uploaded Successfully');
                const copy = [...picturesToValidate];
                const copyItem = copy.find(el => el.id === picturesToValidate[index].id)
                if (copyItem) {
                    copyItem.preview = result;
                    setPicturesToValidate(copy)
                }
            } else {
                setLoading(false)
                setEditHandler(false)
                console.log('error')
            }

            //setBrightness(1)
            //setContrast(1)
            //setSaturation(1)
        });

        //const dataURL = canvas.toDataURL('image/jpeg', 1.0)
        //const blob = dataURItoBlob(dataURL)

    }

    const forward = () => {
        if (picturesToValidate.length && (index + 1 >= picturesToValidate.length)) {
            return;
        }
        setIndex(index + 1)
    }

    const backward = () => {
        if (picturesToValidate.length && (index - 1 < 0)) {
            return;
        }
        setIndex(index - 1)
    }

    const setCropHandler = (c, updateCrop=true) => {

        let canvas = document.getElementById("myCanvas");
        canvas.height = canvas.width = 0;

        let context = canvas.getContext("2d");
        let img = document.getElementById("preview-full");

        if (c.width === 0) c.width = 50
        if (c.height === 0) c.height = 50
        console.log(c)

        if (!canvas || !img) return

        const xC = c.x / originalWidth
        const yC = c.y / originalHeight
        const wC = c.width / originalWidth
        const hC = c.height / originalHeight

        const bigX = Math.floor(xC * originalNaturalWidth)
        const bigY = Math.floor(yC * originalNaturalHeight)
        const bigW = Math.floor(wC * originalNaturalWidth)
        const bigH = Math.floor(hC * originalNaturalHeight)

        canvas.width = bigW
        canvas.height = bigH

        if (typeof context.filter !== "undefined") {
            context.filter = `brightness(${brightness}) contrast(${contrast}) saturate(${saturation})`;
        }

        context.drawImage(img,
            bigX,
            bigY,
            bigW,
            bigH,
            0, 0,
            bigW,
            bigH,
        );

        if (updateCrop) setCrop(c)
    }

    const onImgLoad = (obj) => {
        setOriginalWidth(obj?.target?.offsetWidth || 0)
        setOriginalHeight(obj?.target?.offsetHeight || 0)

        setOriginalNaturalWidth(obj?.target?.naturalWidth || 0)
        setOriginalNaturalHeight(obj?.target?.naturalHeight || 0)
    }

    if (!picturesToValidate.length) return null

    const url = picturesToValidate[index].url
    const preview = picturesToValidate[index].preview

    return (
        <div>
            {loading && <p className='p-10'>uploading...</p>}
            {!edit && !loading &&
                <div className='p-10 d-flex-wrap-sb mb-30' style={{gap: 30}}>
                    <div className='d-flex' style={{gap: 20}}>
                        <button className='button-clean' onClick={() => backward()} disabled={index - 1 < 0}>{`<<`}</button>
                        <button className='button-clean' onClick={() => forward()} disabled={index + 1 === picturesToValidate.length}>{`>>`}</button>
                        <button className='button-secondary' onClick={() => setEditHandler(true)}>{`edit`}</button>
                    </div>
                    <div className='d-flex-ac' style={{gap: 20}}>
                        <p>{picturesToValidate[index].id}</p>
                        <input placeholder='token' className="w-300" type="text" value={token} onChange={(e) => setToken(e.target.value)} />
                        <button className='button-main' onClick={() => publishPicture()}>{`PUBLISH`}</button>
                    </div>
                </div>
            }
            {!edit && !loading &&
                <div className='d-flex-wrap mb-50' style={{gap: 20}}>
                    <div style={{maxWidth: '48%', maxHeight: '80vh'}}>

                        <img style={{maxWidth: '100%', maxHeight: '80vh'}} onLoad={onImgLoad} src={url} alt='crop'/>

                    </div>
                    <div style={{maxWidth: '48%', maxHeight: '80vh'}}>

                        <img style={{maxWidth: '100%', maxHeight: '80vh'}} src={preview} alt='crop'/>

                    </div>
                </div>
            }
            {edit && !loading &&
                <div className='d-flex-wrap mb-50'>
                    <div style={{maxWidth: '48%', maxHeight: '80vh'}}>
                        <h4 className='mb-10'>Original</h4>
                        <ReactCrop style={{maxWidth: '100%', maxHeight: '80vh'}} crop={crop}
                                   onChange={c => setCropHandler(c)}>
                            <img id='preview'
                                 style={{filter: `brightness(${brightness}) contrast(${contrast}) saturate(${saturation})`}}
                                 onLoad={onImgLoad} src={url} alt='crop'/>
                        </ReactCrop>
                    </div>
                    <div className='ml-30' style={{maxWidth: '48%', maxHeight: '80vh'}}>
                        <h4 className='mb-10'>Tools</h4>
                        <div style={{width: originalWidth, originalHeight: originalHeight}}>
                            <div className='mt-30'>
                                <div>brightness</div>
                                <div className='d-flex-ac'>
                                    <input type='range' min={.5} max={1.5} step={.1} value={brightness}
                                           onChange={e => setBrightness(e.target.value)}/>
                                    <span className='ml-10'>{brightness}</span>
                                </div>
                            </div>
                            <div className='mt-30'>
                                <div>contrast</div>
                                <div className='d-flex-ac'>
                                    <input type='range' min={.5} max={1.5} step={.1} value={contrast}
                                           onChange={e => setContrast(e.target.value)}/>
                                    <span className='ml-10'>{contrast}</span>
                                </div>
                            </div>

                            <div className='mt-30'>
                                <div>saturation</div>
                                <div className='d-flex-ac'>
                                    <input type='range' min={.5} max={1.5} step={.1} value={saturation}
                                           onChange={e => setSaturation(e.target.value)}/>
                                    <span className='ml-10'>{saturation}</span>
                                </div>
                            </div>

                            <div className='mt-30'>
                                <button className='button-main' onClick={() => uploadDocument()}>UPLOAD</button>
                            </div>
                            <div className='mt-30'>
                                <button className='button-link' onClick={() => setEditHandler(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {<canvas id="myCanvas" style={{ display: (loading || !edit) ? 'none' : 'block'}} />}
            <img id='preview-full' crossOrigin="anonymous" style={{
                position: 'absolute',
                left: 5000,
                filter: `brightness(${brightness}) contrast(${contrast}) saturate(${saturation})`
            }} src={url} alt='crop'/>
        </div>

    );
}


const mapStateToProps = ({ pictures: { pictures }}) => {
    return { pictures };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSession: updateSession(dispatch),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PictureValidation);
