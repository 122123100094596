
export const PICTURES_LOADED = 'PICTURES_LOADED';
export const PICTURES_FILTER = 'PICTURES_FILTER';
export const PICTURES_ERROR = 'PICTURES_ERROR';

export const CONTENT_LOADED = 'CONTENT_LOADED';
export const CONTENT_ERROR = 'CONTENT_ERROR';

export const SESSION_STARTED = 'SESSION_STARTED';
export const SESSION_UPDATED = 'SESSION_UPDATED';

export const createAction = (type, payload) => {
    return { type, payload }
}
