import React, {useState} from "react";
import { applyFilter } from "../../redux/actions/actions-pictures";
import {connect} from "react-redux";
import VBox from "../ui/layouts/vbox";
import {FiMinus, FiPlus} from "react-icons/fi";
import FilterCategoryItem from "./filter-category-item";

const FilterCategory = props =>  {

    const { data, filterName, filterLabel, applyFilter } = props;

    const [expanded, setExpanded] = useState(false);

    const changeFilterHandler = (index, val) => {
        const copy = [...data]
        data[index].selected = val;
        applyFilter(filterName, copy)
    }

    return (
        <div>
            <div className='d-flex-wrap-sb-ac mb-20 enabledClick' onClick={() => setExpanded(!expanded)}>
                <h3>{filterLabel.toUpperCase()}</h3>
                {expanded ? <FiMinus size={26} /> : <FiPlus size={26} />}
            </div>
            {expanded &&
                <VBox gap={10} full className='mt-20'>
                    {data.map((item, index) => <FilterCategoryItem key={index} item={item} onChange={(val) => changeFilterHandler(index, val)} /> )}
                </VBox>
            }

            <div className='separator mt-20 mb-20'/>
        </div>

    );
}


const mapStateToProps = ({ pictures: { themes, techniques, colors }}) => {
    return { themes, techniques, colors };
};

const mapDispatchToProps = (dispatch) => {
    return {
        applyFilter: applyFilter(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterCategory);
