import React, { useState} from "react";

import {connect} from "react-redux";
import {updateSession} from "../../redux/actions/actions-tracking";

import APIService from "../../services/api-service/api-service";


const apiService = new APIService()

const PictureUploadPage = props =>  {

    const [token, setToken] = useState('');
    const [record, setRecord] = useState('');
    const [fileToUpload, setFileToUpload] = useState('');
    const [loading, setLoading] = useState(false);

    const uploadDocument = async () => {

        if (!fileToUpload) return;

        const formData = new FormData();
        formData.append('file', fileToUpload);
        formData.append('record', record);
        formData.append('token', token);

        setLoading(true)

        const result = await apiService.uploadPicture(formData);

        if (result) {
            setLoading(false)
            window.alert('Uploaded Successfully');
        } else {
            setLoading(false)
            window.alert('Uploading Error!')
            console.log('error')
        }
    }

    return (
        <div>
            <div className='mt-20'>
                <p className='fs-12'>Record ID</p>
                <input className="mt-5 wp-100" type="text" value={record} onChange={(e) => setRecord(e.target.value)} />
            </div>
            <div className='mt-20'>
                <p className='fs-12'>Token</p>
                <input className="mt-5 wp-100" type="text" value={token} onChange={(e) => setToken(e.target.value)} />
            </div>
            <div className='mt-20'>
                <p className='fs-12'>Image to upload</p>
                <input className="mt-5 wp-100" type="file" onChange={(e) => setFileToUpload(e.target.files[0])} />
            </div>
            <div className='mt-20 mb-50'>
                {!loading && <button className='button-main' onClick={() => uploadDocument()}>Upload</button>}
                {loading && <p>Uploading...</p>}
            </div>
        </div>

    );
}


const mapStateToProps = ({ pictures: { pictures }}) => {
    return { pictures };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSession: updateSession(dispatch),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PictureUploadPage);
